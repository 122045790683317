import React, { useState } from 'react';
import SimpleReactFileUpload from '../SimpleReactFileUpload.js';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import { Form, Button, Spinner } from 'react-bootstrap'

import axios, { post } from 'axios';
import { API_BASE_URL } from '../../constants/apiConstants';
import "./Home.css"

function Home(props) {
    const [archiveForDownload, setArchiveForDownload] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fileUpload = (file, org, docType, fromId) => {
        setArchiveForDownload(null)
        setLoading(true)
        setError(null)
        const url = API_BASE_URL + "receipt/" + org + "/" + docType + "?from=" + fromId;
        const formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(url, formData, config)
            .catch(function (error) {
                setLoading(false)
                setError("Dogodila se greška, pokušajte ponovo ili prijavite IT podršci")
            })
            .then(response => {
                if(response.status === 200 || response.status === 201){
                    setError(null)
                    setArchiveForDownload(response)
                }
                else{
                    setError("Dogodila se greška, pokušajte ponovo ili prijavite IT podršci")
                }
               
                setLoading(false)
                return response;
            })
    }

    return (
        <Container>
            <Row className="justify-content-md-center" float="center">
                <Col>
                    <SimpleReactFileUpload onUpload={fileUpload} />
                </Col>
            </Row>

            {archiveForDownload != null &&
                <Row className="justify-content-md-center" float="center">
                    <Col>
                        <a href={API_BASE_URL + "receipt/file/" + archiveForDownload.data.organization + "/" + archiveForDownload.data.outputId}
                            download={archiveForDownload.saveAsFileName}> Preuzmi potvrde </a>
                    </Col>
                </Row>
            }

            {error != null &&
                <Row className="justify-content-md-center" float="center">
                    <Col>
                        { error }
                    </Col>
                </Row>
            }

            <Row className="justify-content-md-center">
                <Col>
                    {loading == true &&
                        <div><Spinner animation="border" variant="primary" /></div>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default Home;
