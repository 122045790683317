import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import { API_BASE_URL } from '../constants/apiConstants';
import axios, { get } from 'axios';

class SimpleReactFileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            org: null,
            docType: "donation",
            fromId: 1,
            organizations: null
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChangeUpload = this.onChangeUpload.bind(this)
        this.fileUpload = props.onUpload.bind(this)
        this.onChangeOrg = v => this.state.org=v
        this.onChangeDocType = v => this.state.docType=v
        this.onChangeFromId= v => this.state.fromId=v

        // Simple GET request using fetch
        axios.get(API_BASE_URL + 'user/config')
            .then(response => {
                this.setState({ 
                    organizations: response.data.organizations,
                    org: response.data.organizations[0].id 
                })
            }
        );
    }

    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.fileUpload(this.state.file, this.state.org, this.state.docType, this.state.fromId).then((response) => {
            //console.log(response.data);
        })
    }

    onChangeUpload(e) {
        this.setState({ file: e.target.files[0] })
    }

    render() {
        let orgButtonGroup
        if (this.state.organizations && this.state.org) {
            const orgItems = this.state.organizations.map((org) =>
                <ToggleButton  variant="secondary" value={org.id}>{org.name}</ToggleButton>
            )

            orgButtonGroup = <ToggleButtonGroup aria-label="Org" name="organization" onChange={value => this.state.org=value} defaultValue={this.state.org}>{orgItems}</ToggleButtonGroup>
        } else {
            orgButtonGroup = null
        }
    
        return (
            <Form>
                <Form.Row className="justify-content-md-center" >
                    <Form.Group as={Col} controlId="org">
                        <Form.Label>Organizacija</Form.Label>
                        <br/>
                        {orgButtonGroup}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="type">
                        <Form.Label>Vrsta potvrde</Form.Label>
                        <br/>
                        <ToggleButtonGroup aria-label="Doc type" name="docType" onChange={this.onChangeDocType} defaultValue="donation" >
                            <ToggleButton variant="secondary" value="membership">Članarina</ToggleButton>
                            <ToggleButton variant="secondary" value="donation">Donacija</ToggleButton>
                            <ToggleButton variant="secondary" value="statement">Izjava</ToggleButton>
                        </ToggleButtonGroup>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="from">
                        <Form.Label>Od potvrde broj:</Form.Label>
                        <Form.Control type="number" step={1} defaultValue="1" onChange={(e) => this.onChangeFromId(e.target.value) } />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="file">
                        <Form.File id="file1" label="Odaberi tablicu za upload" onChange={(e) => this.onChangeUpload(e)} />
                    </Form.Group>
                </Form.Row>

                <Button variant="primary" type="submit" onClick={this.onFormSubmit} disabled={this.state.file == null}>
                    Generiraj
                </Button>
            </Form>

            //   <form onSubmit={this.onFormSubmit}>
            //     <h1>File Upload</h1>
            //     <input type="file" onChange={this.onChange} />
            //     <button type="submit">Upload</button>
            //   </form>
        )
    }
}

export default SimpleReactFileUpload
